<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="card">
        <DataTable :value="storecostrulesetlist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            stripedRows :scrollHeight="sHeight" :scrollable="true" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="pagesize" :virtualScrollerOptions="vsOptionFun()"
            :rowsPerPageOptions="[50, 100, 200, 500]" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="listPage($event)">
            <template #header>
                <div class="grid">
                    <div class="col-4">
                        <div class="text-left">
                            <InputText placeholder="店铺" id='searchstoreNameInput' v-model="searchName" type="text" />
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="text-right">
                            <Button label="查询" icon="pi pi-search" @click="loadData(1)"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="新建" icon="pi pi-plus" @click="createdetail()"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="删除" icon="pi pi-times" @click="deldetail()" class="button-danger" />
                        </div>
                    </div>
                </div>
            </template>
            <Column field="storename" header="店铺" frozen style="width: 20%">
                <template #body="slotProps">
                    <Button :label="slotProps.data.storename" @click="opendetail(slotProps.data)"
                        class="p-button-link" />
                </template>
            </Column>
            <Column field="daylowerlimit" header="起始天数" style="width: 20%"></Column>
            <Column field="dayupperlimit" header="截止天数" style="width: 20%">
            </Column>
            <Column field="cost" header="费用($)" style="width: 20%"></Column>
            <Column field="effectivetime" header="生效日期" style="width: 20%"></Column>
        </DataTable>
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <div class="card p-fluid">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :pageJsonConfig="pageJsonConfig" :freshListMethod="freshList" :isReadOnly="readOnly" />
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import ConfigService from '../../../service/ConfigService';

    export default {
        setup() {
            const confirm = useConfirm();
            return {
                confirm
            };
        },
        data() {
            return {
                selectedData: ref(),
                searchName: ref(),
                currentRow: {
                    bindpage: 'storecostrule',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                loading: false,
                butLoading: false,
                displayMaximizable: false,
                recordsubject: '',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                storecostrulesetlist: [],
                sHeight: '650px',
                readOnly: false,
                pageJsonConfig: {},
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getstorecostrule(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.pageJsonConfig = data.page;
                    this.sHeight = (window.innerHeight - 300) + "px";
                    this.loadData(1);
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            loadData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'storename',
                        value: this.searchName,
                        operation: 'contains',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{storecostrulelist(where:" + JSON.stringify(listwhere) +
                    "){id storeid storename daylowerlimit dayupperlimit cost effectivetime}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.storecostrulesetlist = jsonData.data.storecostrulelist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                console.log(page);
                this.loadData(page + 1);
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            opendetail(v_row) {
                this.currentRow = {
                    isCopy: false,
                    bindpage: 'storecostrule',
                    id: v_row.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.displayMaximizable = true;
                this.recordsubject = v_row.storename;
            },
            createdetail() {
                this.currentRow = {
                    bindpage: 'storecostrule',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.displayMaximizable = true;
                this.recordsubject = '新建';
            },
            deldetail() {
                console.log(this.selectedData);
                if (this.selectedData['id']) {
                    this.loading = true;
                    var id = this.selectedData['id'];
                    this.confirm.require({
                        message: '确认要删除?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{storecostrule(o:' + JSON.stringify(this
                                    .selectedData) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.storecostrulesetlist.length; i++) {
                                        if (this.storecostrulesetlist[i]['id'] == id) {
                                            this.storecostrulesetlist.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                }
            },
            closeMaximizable() {
                this.closeButLoading();
                this.displayMaximizable = false;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
        }
    }
</script>